import React, { Component } from 'react'
import { Container, Row, Col, Card, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import DateRangeIcon from '@material-ui/icons/DateRange'
import Slider from "react-slick"
import { replaceURL } from '../../migration'

const api = axios.create({
    baseURL: 'https://bacchus-api.whitelabel-hosting.de/api'
})

export class EventView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            events: [],
            eventAmount: 0
        }
    }

    getEvents = async () => {
        api.get(`/items/events?filter[status]=published`).then(res => {
            this.setState({ loading: false, events: replaceURL(res).data.data });
            let event_amount = replaceURL(res).data.data.length
            this.setState({eventAmount:event_amount})
        })
    }

    componentDidMount() {
        this.getEvents();
    }

    render() {

        const settings = {
            draggable: true,
            dots: false,
            arrows: false,
            speed: 2000,
            slidesPerRow: 1,
            slidesToShow: this.state.event_amount,
            slidesToScroll: 1,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                    slidesToShow: this.state.event_amount,
                    slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                    slidesToShow: this.state.event_amount,
                    slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                    slidesToShow: this.state.event_amount,
                    slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                    slidesToShow: this.state.event_amount,
                    slidesToScroll: 1,
                    }
                }
            ]
        };

        function handleSectionColor(type) {
            if(type === "gray") {
                return "bg-light"
            }
        }

        function handleDate(date){
            let y = date.substring(0,4)
            let m = date.substring(5,7)
            let d = date.substring(8,10)

            date = d + "." + m + "." + y
            return date;
        }

        // render html directly from api
        function createMarkup(content) {
            return {__html: content};
        }
        // end:: render html directly from api

        // Events
        const events = this.state.events.map((event, key) =>
            <Col xs={12}>
                <Link to={"/event/" + event.id} className="link-unstyled">
                    <Card className="event-card">
                        <Card.Body>

                            <div className="event-card-title">
                                <DateRangeIcon style={{ marginRight: '10px' }} />
                                {event.name}
                            </div>

                            <div className="event-card-date">
                                {"am " + handleDate(event.date)}
                            </div>

                            <div className="event-card-description" dangerouslySetInnerHTML={createMarkup(event.excerpt)}>
                            </div>

                        </Card.Body>
                    </Card>
                </Link>
            </Col>
        );
        // End:: Events

        return (
                <div>
                    <div className={handleSectionColor(this.props.sectiontype)}>
                        <div className="text-section">
                            <Container>
                                <Row>
                                    <Col>
                                        <h3 className="text-section-heading">Aktuelle Veranstaltungen</h3>
                                        {/* <Slider {...settings}> */}
                                            <Row>
                                                {events}
                                            </Row>
                                        {/* </Slider> */}
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
        )
    }
}

export default EventView
