import React, { Component } from 'react'
import ProductSection from '../components/ProductSection'
import ProductView from '../components/products/ProductView'
import ContactSection from '../components/layout/ContactSection'
import Carousel from '../components/Carousel'
import MetaTags from 'react-meta-tags'
import bacchusog from '../img/bacchus-og.png'

export class Products extends Component {
    render() {
        return (
            <div>
                <MetaTags>
                    <title>Sortiment - Weinhandel Bacchus</title>
                    <meta name="description" content="Unser internationales Sortiment ist der Tradition verpflichtet und der Moderne angepasst!" />
                    <meta property="og:title" content="Sortiment - Weinhandel Bacchus" />
                    <meta property="og:image" content={bacchusog} />
                </MetaTags>
                <Carousel slideid={2} />
               <ProductSection />
               <ProductView space="no" title="Aktuelle Topseller" category="topseller" sectiontype="gray" />
               <ProductView title="Aktuelle Angebote" category="angebote" sectiontype="gray" />
               <ContactSection />
            </div>
        )
    }
}

export default Products
