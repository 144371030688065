import React, { Component } from 'react'
import GiftSection from '../components/GiftSection'
import WelcomeSection from '../components/WelcomeSection'
import ServiceSection from '../components/ServiceSection'
import ProductView from '../components/products/ProductView'
import EventView from '../components/products/EventView'
import ContactSection from '../components/layout/ContactSection'
import Carousel from '../components/Carousel'
import MetaTags from 'react-meta-tags'
import bacchusog from '../img/bacchus-og.png'

export class Home extends Component {
    render() {
        return (
            <div>
                <MetaTags>
                    <title>Startseite - Weinhandel Bacchus</title>
                    <meta name="description" content="Der Bacchus-Weinhandel heißt Sie willkommen und verspricht nur, was er auch halten kann!" />
                    <meta property="og:title" content="Startseite - Weinhandel Bacchus" />
                    <meta property="og:image" content={bacchusog} />
                </MetaTags>
                <Carousel slideid={1}/>
                <WelcomeSection />
                <ServiceSection sectiontype="gray" />
                <GiftSection />
                <ProductView space="no" title="Aktuelle Angebote" category="angebote" sectiontype="gray" />
                <ProductView title="Aktuelle Topseller" category="topseller" sectiontype="gray" />
                <EventView />
                <ContactSection color="gray" />
            </div>
        )
    }
}

export default Home
