import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { sign, toggleAlert, setProductCategory, setProductSubCategory, setProductCountry, setProductSearch } from '../actions'
import { Container, Row, Col, Card, Image, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ProductFilters from '../components/ProductFilters'
import MetaTags from 'react-meta-tags'
import bacchusog from '../img/bacchus-og.png'
import { replaceURL } from '../migration'

const api = axios.create({
    withCredentials: true,
    baseURL: 'https://bacchus-api.whitelabel-hosting.de/api'
})

// map redux state to props
const mapStateToProps = state => {
    return {
        isLogged: state.isLogged,
        Alert: state.Alert,
        ProductCategory: state.ProductCategory,
        ProductSubCategory: state.ProductSubCategory,
        ProductCountry: state.ProductCountry,
        ProductSearch: state.ProductSearch,
    }
  }
  // end:: map redux state to props
  
  // map redux dispatcher to props
  const mapDispatchToProps = dispatch => {
    return {
        sign: (payload) => dispatch(sign(payload)),
        toggleAlert: (payload) => dispatch(toggleAlert(payload)),
        setProductCategory: (payload) => dispatch(setProductCategory(payload)),
        setProductSubCategory: (payload) => dispatch(setProductSubCategory(payload)),
        setProductCountry: (payload) => dispatch(setProductCountry(payload)),
        setProductSearch: (payload) => dispatch(setProductSearch(payload)),
    }
  }
  // end:: map redux dispatcher to props

export class ProductList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            products: [],
            categories: [],
            subcategories: [],
            countries: [],
        }
    }

    // handle search input
    handleSearchChange = (event) => {
        this.props.setProductSearch(event.target.value)
        this.getProducts(event.target.value, this.props.ProductCategory, this.props.ProductSubCategory, this.props.ProductCountry);
    }
    // end:: handle search input

    handleCategoryChange = (event) => {
        this.props.setProductCategory(event.target.value)
        this.getProducts(this.props.ProductSearch, event.target.value, this.props.ProductSubCategory, this.props.ProductCountry);
    }

    handleSubCategoryChange = (event) => {
        this.props.setProductSubCategory(event.target.value)
        this.getProducts(this.props.ProductSearch, this.props.ProductCategory , event.target.value, this.props.ProductCountry);
    }

    handleCountryChange = (event) => {
        this.props.setProductCountry(event.target.value)
        this.getProducts(this.props.ProductSearch, this.props.ProductCategory , event.target.ProductSubCategory, event.target.value);
    }

    getProducts = async (query=this.props.ProductSearch, catFilters=this.props.ProductCategory, subcatFilters=this.props.ProductSubCategory, countryFilters=this.props.ProductCountry) => {
        api.get(`/items/products?filter[kategorie][contains]=${catFilters}&filter[unterkategorie][contains]=${subcatFilters}&filter[herkunft][contains]=${countryFilters}&q=${query}`).then(res => {
            this.setState({ loading: false, products: replaceURL(res).data.data });
        })
    }

    getCategories = async () => {
        api.get(`/items/categories`).then(res => {
            this.setState({ loading: false, categories: replaceURL(res).data.data });
        })
    }

    getSubCategories = async () => {
        api.get(`/items/subcategories?filter[name][contains]=${this.props.ProductCategory}`).then(res => {
            this.setState({ loading: false, subcategories: replaceURL(res).data.data });
        })
    }

    getCountries = async () => {
        api.get(`/items/countries`).then(res => {
            this.setState({ loading: false, countries: replaceURL(res).data.data });
        })
    }

    componentDidMount() {
        this.getProducts();
        this.getCategories();
        this.getSubCategories();
        this.getCountries();
    }

    render() {

        function handleVK(vk) {
            if(vk === null) {
                return "Preis auf Anfrage"
            }
            else {
                return vk.toFixed(2).replace(".", ",") + " €"
            }
        }

        // Produtcs
        const products = this.state.products.map((product, key) =>
            <Col className="product-col" xs={12} lg={4}>
                <Link title={"Zum Produkt: " + product.name} to={"/product/" + product.id} className="link-unstyled">
                    <Card className="product-card">
                        <Card.Body>

                        <div className="d-flex justify-content-center">
                            {product.generated_images ? (<Image fluid className="product-card-image" fluid src={product.generated_images[0]} />) : (<Image fluid className="product-card-image" fluid src="https://bacchus-api.whitelabel-hosting.de/api/assets/f49zg9087u0oskgo" />)}
                        </div>

                            <div className="product-card-title">
                                {product.name}
                            </div>

                            <div className="product-card-subtitle">
                                {product.kategorie} - {product.unterkategorie}
                            </div>

                            <div className="d-flex flex-row">

                                <div className="product-card-price">
                                {product.inhalt} L / {product.alkoholgehalt} Alk.
                                </div>

                                <div className="product-card-price ml-auto">
                                {handleVK(product.vk)}
                                </div>

                            </div>

                        </Card.Body>
                    </Card>
                </Link>
            </Col>
        );
        // End:: Produtcs

        // Category
        const categories = this.state.categories.map((category, key) =>
            <option value={category.name}>
                {category.name}
            </option>
        );
        // End:: Category

        // Category
        const subcategories = this.state.subcategories.map((subcategory, key) =>
            <option value={subcategory.name}>
                {subcategory.name}
            </option>
        );
        // End:: Category

        // Category
        const countries = this.state.countries.map((country, key) =>
            <option value={country.name}>
                {country.name}
            </option>
        );
        // End:: Category


        return (
            <div>
                <MetaTags>
                    <title>Produkte - Weinhandel Bacchus</title>
                    <meta name="description" content="Entdecken Sie unsere Produktliste." />
                    <meta property="og:title" content="Produkte - Weinhandel Bacchus" />
                    <meta property="og:image" content={bacchusog} />
                </MetaTags>
                <Container className="products-section">

                    <Row>
                        <Col xs={12} lg={12}>
                            <Form>
                                <Row>
                                    <Col xs={12} lg={12}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control value={this.props.ProductSearch} onChange={this.handleSearchChange} type="text" placeholder="Suche" />
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} lg={4}>
                                        <Form.Group>
                                            <Form.Control onChange={this.handleCategoryChange} as="select">
                                            <option name="ProductSearch" value="" selected>Kategorie wählen</option>
                                                {categories}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} lg={4}>
                                        <Form.Group>
                                            <Form.Control onChange={this.handleSubCategoryChange} as="select">
                                            <option value="" selected>Unterkategorie wählen</option>
                                                {subcategories}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} lg={4}>
                                        <Form.Group>
                                            <Form.Control onChange={this.handleCountryChange} as="select">
                                            <option value="" selected>Herkunft wählen</option>
                                                {countries}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>

                    <Row>
                        {products}
                    </Row>

                </Container>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)
