const ProductCountry = (state = "", action) => {
    switch(action.type){
        case "SETPRODUCTCOUNTRY":
            state = action.payload;
        default:
            return state;
    }
};

export default ProductCountry;
