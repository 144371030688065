import React, { Component } from 'react'
import ContactSection from '../components/layout/ContactSection'
import NewsletterForm from '../components/NewsletterForm'
import MetaTags from 'react-meta-tags'
import bacchusog from '../img/bacchus-og.png'

export class Newsletter extends Component {
    render() {
        return (
            <div>
                <MetaTags>
                    <title>Newsletter Anmeldung - Weinhandel Bacchus</title>
                    <meta name="description" content="Anmeldung zum Newsletter des Weinhandel Bacchus. Verpassen Sie keine Neuigkeiten mehr." />
                    <meta property="og:title" content="Newsletter - Weinhandel Bacchus" />
                    <meta property="og:image" content={bacchusog} />
                </MetaTags>
                <NewsletterForm />
                <ContactSection color="gray" />
            </div>
        )
    }
}

export default Newsletter
