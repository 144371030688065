import React, { Component } from 'react'
import GiftSection from '../components/GiftSection'
import WelcomeSection from '../components/WelcomeSection'
import ServiceSection from '../components/ServiceSection'
import ProductView from '../components/products/ProductView'
import EventView from '../components/products/EventView'
import ContactSection from '../components/layout/ContactSection'
import Carousel from '../components/Carousel'
import MetaTags from 'react-meta-tags'
import bacchusog from '../img/bacchus-og.png'
import { Container, Row, Col, Card } from 'react-bootstrap'

export class Home extends Component {
    render() {
        return (
            <div>
                <MetaTags>
                    <title>Startseite - Weinhandel Bacchus</title>
                    <meta name="description" content="Der Bacchus-Weinhandel heißt Sie willkommen und verspricht nur, was er auch halten kann!" />
                    <meta property="og:title" content="Startseite - Weinhandel Bacchus" />
                    <meta property="og:image" content={bacchusog} />
                </MetaTags>
                <Container className="single-section">
                    <Row>
                        <Col xs={12} lg={12}>
                            <Card>
                                <Card.Body>
                                <h1>Impressum</h1>
                                <h2>Angaben gemäß § 5 TMG:</h2>
                                <p>Weinhandel Bacchus<br />Djawed Grünewald e.K.</p>
                                <h3>Postanschrift:</h3>
                                <p>Glückensteinweg 65<br />61350 Bad Homburg<br /></p>
                                <h3>Kontakt:</h3>
                                <p>Telefon: +49617222526<br />E-Mail: info@weinhandel-bacchus.de</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Home
