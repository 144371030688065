import React, { Component } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { sign, toggleAlert, setProductCategory, setProductSubCategory, setProductCountry, setProductSearch } from '../actions'
import { Container, Row, Col, Card, Image, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { replaceURL } from '../migration'

const api = axios.create({
    withCredentials: true,
    baseURL: 'https://bacchus-api.whitelabel-hosting.de/api'
})

// map redux state to props
const mapStateToProps = state => {
    return {
        ProductCategory: state.ProductCategory,
        ProductSubCategory: state.ProductSubCategory,
        ProductCountry: state.ProductCountry,
        ProductSearch: state.ProductSearch,
    }
  }
  // end:: map redux state to props
  
  // map redux dispatcher to props
  const mapDispatchToProps = dispatch => {
    return {
        setProductCategory: (payload) => dispatch(setProductCategory(payload)),
        setProductSubCategory: (payload) => dispatch(setProductSubCategory(payload)),
        setProductCountry: (payload) => dispatch(setProductCountry(payload)),
        setProductSearch: (payload) => dispatch(setProductSearch(payload)),
    }
  }
  // end:: map redux dispatcher to props

export class ProductFilters extends Component {

    constructor(){
        super()
        this.state = {
            ProductCategory: "",
            ProductSubCategory: "",
            ProductCountry: "",
            ProductSearch: "",
        }
    }

    // handle search input
    handleSearchChange = (event) => {
        this.props.productSearch(event.target.value)
        this.getProducts(event.target.value, this.props.productCategory, this.props.productSubCategory, this.props.productCountry);
    }
    // end:: handle search input

    // get products
    getProducts = async (query=this.props.productSearchFilter, catFilters=this.props.productCategoryFilter, brandFilters=this.props.productManufacturerFilter) => {
        api.get(`/items/products?limit=48&q=${query}${catFilters}${brandFilters}`).then(res => {
            this.setState({ loading: false, products: replaceURL(res).data.data });
        })
    }
    // end:: get products

    componentDidMount() {
        this.getProducts(this.props.productSearchFilter, this.props.productCategoryFilter, this.props.productManufacturerFilter);
    }

    render() {

        if(this.state.ProductCategory != this.props.ProductCategory) {
            this.props.ProductCategory(this.state.ProductCategory)
        }

        return (
            <Row>
               <Col>
                <Form>
                    <Row>
                        <Col xs={12} lg={12}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control type="text" placeholder="Suche" />
                            </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group>
                            <Form.Control as="select">
                            <option value="" selected>Kategorie wählen</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            </Form.Control>
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group>
                            <Form.Control as="select">
                            <option value="" selected>Unterkategorie wählen</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            </Form.Control>
                        </Form.Group>
                        </Col>
                        <Col>
                        <Form.Group>
                            <Form.Control as="select">
                            <option value="" selected>Herkunft wählen</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            </Form.Control>
                        </Form.Group>
                        </Col>
                    </Row>
                </Form>
                </Col>
            </Row>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductFilters)
