import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter} from "react-router-dom"
import { createStore } from 'redux'
import allReducer from './reducers'
import { Provider } from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// Redux create store and init dev tools
const store = createStore (
  allReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
// end:: Redux create store and init dev tools

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);