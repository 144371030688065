import React, { Component } from 'react'
import Slider from "react-slick"
import axios from 'axios'
import { Link } from 'react-router-dom'
import { replaceURL } from '../migration'

const api = axios.create({
    baseURL: 'https://bacchus-api.whitelabel-hosting.de/api'
})

export class CategoryCarousel extends Component {

    constructor(props) {
        super(props)
        this.state = {
            categories: []
        }
    }

    getCategories = async () => {
        api.get(`/items/categories?filter[status]=published`).then(res => {
            this.setState({ loading: false, categories: replaceURL(res).data.data });
        })
    }

    componentDidMount() {
        this.getCategories();
    }

    render() {

        const settings = {
            draggable: true,
            dots: false,
            arrows: false,
            speed: 2000,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    }
                }
            ]
        };

        // Categories
        const categories = this.state.categories.map((category, key) =>
            <div title={category.name}>
                <Link className="link-unstyled" to="/products">
                    {category.name}
                </Link>
            </div>
        );
        // End:: Categories

        return (
            <div className="category-carousel">
                <Slider {...settings}>
                    {categories}
                </Slider>
            </div>
        )
    }
}

export default CategoryCarousel
