import React, { Component } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { connect } from 'react-redux'
import { sign, toggleAlert } from '../actions'
import axios from 'axios'

const api = axios.create({
    baseURL: 'https://bacchus-api.whitelabel-hosting.de/api'
})

// map redux state to props
const mapStateToProps = state => {
    return {
        isLogged: state.isLogged,
        Alert: state.Alert,
    }
  }
// end:: map redux state to props
  
// map redux dispatcher to props
const mapDispatchToProps = dispatch => {
    return {
        sign: (payload) => dispatch(sign(payload)),
        toggleAlert: (payload) => dispatch(toggleAlert(payload)),
    }
}
// end:: map redux dispatcher to props

export class ContactForm extends Component {

    constructor() {
        super()
        this.state = {
            name: "",
            email: "",
            message: "",
            agb: ""
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    render() {
        return (
            <div>
                <Container>
                    <Row className="justify-content-center">
                        <Col className="" xs={12} lg={8}>
                            <div className="form-section">
                                <h3 className="text-section-heading">Kontaktformular</h3>
                                <Form>

                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control name="name" value={this.state.name} onChange={this.handleChange} type="text" placeholder="Ihr Name" />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Control name="email" value={this.state.email} onChange={this.handleChange} type="email" placeholder="Ihre E-Mail Adresse" />
                                    </Form.Group>

                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Control name="message" value={this.state.message} onChange={this.handleChange} as="textarea" rows="3"  placeholder="Ihre Nachricht..." />
                                    </Form.Group>

                                    <Form.Group controlId="formBasicCheckbox">
                                        <Form.Check name="agb" onClick={() => {
                                            this.setState({agb: "true"})
                                        }} value={this.state.agb} type="checkbox" label="Hiermit akzeptiere ich die Bestimmungen zur Auftragsverarbeitung." />
                                    </Form.Group>

                                    <Button onClick={() => {

                                        // Validation
                                        function validateLength(input, length){
                                            if(input.length >= length){
                                                return input;
                                            }
                                        }
                                        function validateAGB(input) {
                                            if(input === "true"){
                                                return input;
                                            }
                                        }
                                        // end:: Validation

                                        api.post('/items/contact_form', {
                                            name: validateLength(this.state.name, 6),
                                            email: validateLength(this.state.email, 5),
                                            message: validateLength(this.state.message, 10),
                                            agb: validateAGB(this.state.agb)
                                        })

                                        .then(function (response) {
                                            this.setState({
                                                name: '', 
                                                email: '', 
                                                message: '', 
                                                agb: '', 
                                            });
                                            this.props.toggleAlert({"show": true, "alert": {"type": "success", "title": 'Anfrage erfolgreich!', "message": "Wir haben Ihre Anfrage erhalten und werden uns schnellstmöglich mit Ihnen in Verbindung setzen."}})
                                        }.bind(this))
        
                                        .catch(function (error) {
                                            this.props.toggleAlert({"show": true, "alert": {"type": "error", "title": 'Anfrage fehlgeschlagen...', "message": "Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut."}})
                                        }.bind(this))
        

                                    }} className="b-btn" variant="secondary">
                                        Nachricht senden
                                    </Button>

                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm)
