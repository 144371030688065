import React, { Component } from 'react'
import ContactSection from '../components/layout/ContactSection'
import EventView from '../components/products/EventView'
import Carousel from '../components/Carousel'
import MetaTags from 'react-meta-tags'
import bacchusog from '../img/bacchus-og.png'

export class Events extends Component {
    render() {
        return (
            <div>
                <MetaTags>
                    <title>Veranstaltungen - Weinhandel Bacchus</title>
                    <meta name="description" content="Aktuelle Veranstaltungen des Weinhandel Bacchus. Seien Sie beim nächsten mal dabei!" />
                    <meta property="og:title" content="Veranstaltungen - Weinhandel Bacchus" />
                    <meta property="og:image" content={bacchusog} />
                </MetaTags>
                <Carousel slideid={3} />
                <EventView />
               <ContactSection color="gray" />
            </div>
        )
    }
}

export default Events
