import React, { Component } from 'react'
import Iframe from 'react-iframe'
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap'

export class ContactSection extends Component {
    render() {

        function sectionColor(color) {
            if(color === "gray") {
                return "bg-light contact-section"
            } else {
                return "contact-section"
            }
        }

        return (
            <div className={sectionColor(this.props.color)}>
                <Container>
                    <Row>
                        <Col xs={12} lg={12} className="google-map-wrapper">
                            <Iframe
                                url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2551.904264289556!2d8.601321315947098!3d50.23769341055281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd079b7cc9c4d3%3A0x1f49c2f7ec1e9e2e!2sBacchus-Weinhandel!5e0!3m2!1sde!2sde!4v1597319109514!5m2!1sde!2sde"
                                width="100%"
                                height="100%"
                                frameBorder="0"
                                id="myId"
                                display="initial"
                                position="relative"
                                className="google-map"
                            />
                        </Col>
                        <Col className="contact-content-section" xs={12} lg={6}>
                            <div className="contact-item">
                                Mo. - Fr. 09:00 - 19:00 Uhr
                            </div>
                            <div className="contact-item">
                                Sa. 09:00 - 15:00 Uhr
                            </div>
                        </Col>
                        <Col className="contact-content-section" xs={12} lg={6}>
                            <div className="contact-item">
                                <a className="link-unstyled" href="tel:+49617222526">+49 6172 22526</a>
                            </div>
                            <div className="contact-item">
                                <a className="link-unstyled" href="mailto:info@weinhandel-bacchus.de">info@weinhandel-bacchus.de</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default ContactSection
