import React, { Component } from 'react'
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap'
import axios from 'axios'
import ContactSection from '../components/layout/ContactSection'
import MetaTags from 'react-meta-tags'
import bacchusog from '../img/bacchus-og.png'
import { replaceURL } from '../migration'

const api = axios.create({
    baseURL: 'https://bacchus-api.whitelabel-hosting.de/api'
})

export class SingleProduct extends Component {

    constructor(props) {
        super(props)
        this.state = {
            product: []
        }
    }

    getProducts = async () => {
        api.get(`/items/products/${this.props.match.params.id}`).then(res => {
            this.setState({ loading: false, product: replaceURL(res).data.data });
        })
    }

    componentDidMount() {
        this.getProducts();
    }

    render() {

        function handleVK(vk) {
            if(vk === null) {
                return "Preis auf Anfrage"
            }
            else {
                return vk.toFixed(2).replace(".", ",") + " €"
            }
        }

        // render html directly from api
        function createMarkup(content) {
            return {__html: content};
        }
        // end:: render html directly from api

        return (
            <div>
                <MetaTags>
                    <title>{this.state.product.name ? (this.state.product.name) : ("Einzelansicht Produkt")} - Weinhandel Bacchus</title>
                    <meta name="description" content={this.state.product.name ? (this.state.product.name) : ("Einzelansicht Produkt")} />
                    <meta property="og:title" content={this.state.product.name ? (this.state.product.name) : ("Einzelansicht Produkt")}/>
                    <meta property="og:image" content={bacchusog} />
                </MetaTags>
               <Container>
                    <Row>
                        <Col>
                            <div className="single-section">
                                <Card>
                                    <Card.Body>
                                    {this.state.product.generated_images ? (<Image fluid className="product-card-image-single" fluid src={this.state.product.generated_images[0]} />) : (<Image fluid className="product-card-image-single" fluid src="https://bacchus-api.whitelabel-hosting.de/api/assets/f49zg9087u0oskgo" />)}
                                    <div className="single-product-title">{this.state.product.name}</div>
                                    <div className="single-product-subtitle">{this.state.product.kategorie} - {this.state.product.unterkategorie}</div>
                                    <div className="single-product-subtitle">{this.state.product.inhalt} L / {this.state.product.alkoholgehalt} Alkoholgehalt</div>
                                    <div className="d-flex flex-row">
                                        <div>{this.state.product.vk ? (handleVK(this.state.product.vk)) : ("")}</div>
                                        <div className="ml-3">{this.state.product.vpe}</div>
                                    </div>
                                    {this.state.product.description ? (<hr className="pb-2 pt-1" />) : ("")}
                                    {this.state.product.description ? (<div className="single-product-desc-title">Über dieses Produkt:</div>) : ("")}
                                    <div className="single-product-desc" dangerouslySetInnerHTML={createMarkup(this.state.product.description)}></div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <ContactSection color="gray" />
            </div>
        )
    }
}

export default SingleProduct
