const Alert = (state = {"show": false, "alert": {"type": "", "title": '', "message": ""}}, action) => {
    switch(action.type){
        case "TOGGLEALERT":
            state = action.payload
            return action.payload
        default:
            return state
    }
};

export default Alert;