export const sign = (payload) => {
    return {
        type: 'SIGN',
        payload: payload
    };
};

export const toggleAlert = (payload) => {
    return {
        type: 'TOGGLEALERT',
        payload: payload
    }
};

export const setProductCategory = (payload) => {
    return {
        type: 'SETPRODUCTCATEGORY',
        payload: payload
    }
};

export const setProductSubCategory = (payload) => {
    return {
        type: 'SETPRODUCTSUBCATEGORY',
        payload: payload
    }
};

export const setProductCountry = (payload) => {
    return {
        type: 'SETPRODUCTCOUNTRY',
        payload: payload
    }
};

export const setProductSearch = (payload) => {
    return {
        type: 'SETPRODUCTSEARCH',
        payload: payload
    }
};