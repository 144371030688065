import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import CategoryCarousel from './CategoryCarousel'
import axios from 'axios'
import { replaceURL } from '../migration'

const api = axios.create({
    baseURL: 'https://bacchus-api.whitelabel-hosting.de/api'
})

export class ProductSection extends Component {

    constructor(props) {
        super(props)
        this.state = {
            content: []
        }
    }

    getContent = async () => {
        api.get(`/items/text_blocks/4`).then(res => {
            this.setState({ loading: false, content: replaceURL(res).data.data });
        })
    }

    componentDidMount() {
        this.getContent();
    }

    render() {

        // render html directly from api
        function createMarkup(content) {
            return {__html: content};
        }
        // end:: render html directly from api

        function handleSectionColor(type) {
            if(type === "gray") {
                return "bg-light"
            }
        }

        return (
            <div className={handleSectionColor(this.props.sectiontype)}>
                <div className="single-section text-center">
                    <Container>
                        <Row>
                            <Col>
                                <h3 className="text-section-heading">{this.state.content.title}</h3>
                                <p dangerouslySetInnerHTML={createMarkup(this.state.content.content)}></p>
                                <CategoryCarousel />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default ProductSection
