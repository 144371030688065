import React, { Component } from 'react'
import { Container, Row, Col, Card, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Slider from "react-slick"
import { replaceURL } from '../../migration'

const api = axios.create({
    baseURL: 'https://bacchus-api.whitelabel-hosting.de/api'
})

export class ProductView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            products: [],
        }
    }

    getProducts = async () => {
        api.get(`/items/products?filter[status]=published&filter[display_category]=${this.props.category}&sort=order`).then(res => {
            this.setState({ loading: false, products: replaceURL(res).data.data });
        })
    }

    componentDidMount() {
        this.getProducts();
    }

    render() {

        function handleSectionColor(type) {
            if(type === "gray") {
                return "bg-light"
            }
        }

        function handleVK(vk) {
            if(vk === null) {
                return "Preis auf Anfrage"
            }
            else {
                return vk.toFixed(2).replace(".", ",") + " €"
            }
        }

        // Produtcs
        const products = this.state.products.map((product, key) =>
            <Link title={"Zum Produkt: " + product.name} to={"/product/" + product.id} className="link-unstyled">
                <Card className="product-card">
                    <Card.Body>

                        <div className="d-flex justify-content-center">
                            {product.generated_images ? (<Image fluid className="product-card-image" fluid src={product.generated_images[0]} />) : (<Image fluid className="product-card-image" fluid src="https://bacchus-api.whitelabel-hosting.de/api/assets/f49zg9087u0oskgo" />)}
                        </div>

                        <div className="product-card-title">
                            {product.name}
                        </div>

                        <div className="product-card-subtitle">
                            {product.kategorie} - {product.unterkategorie}
                        </div>

                        <div className="d-flex flex-row">

                            <div className="product-card-price">
                            {product.inhalt} L / {product.alkoholgehalt} Alk.
                            </div>

                            <div className="product-card-price ml-auto">
                            {handleVK(product.vk)}
                            </div>

                        </div>

                    </Card.Body>
                </Card>
            </Link>
        );
        // End:: Produtcs

        function handleSpace(space) {
            if(space === "no"){
                return "cropped-section"
            } else {
                return "text-section"
            }
        }

        const settings = {
            draggable: false,
            dots: false,
            arrows: false,
            speed: 2000,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    }
                }
            ]
        };

        return (
                <div className={handleSectionColor(this.props.sectiontype)}>
                    <div className={handleSpace(this.props.space)}>
                    <Container>
                        <Row>
                            <Col>
                                <h3 className="text-section-heading">{this.props.title}</h3>
                                <Slider {...settings}>
                                    {products}
                                </Slider>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default ProductView
