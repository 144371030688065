import React, { Component } from 'react'
import './App.css'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { sign, toggleAlert } from './actions'
import axios from 'axios'
import Home from './pages/Home'
import Navigation from './components/layout/Navigation'
import Footer from './components/layout/Footer'
import SingleProduct from './pages/SingleProduct'
import SingleEvent from './pages/SingleEvent'
import Products from './pages/Products'
import ProductList from './pages/ProductList'
import Datenschutz from './pages/Datenschutz'
import Impressum from './pages/Impressum'
import Events from './pages/Events'
import Contact from './pages/Contact'
import Newsletter from './pages/Newsletter'
import SweetAlert from 'sweetalert2-react'

const api = axios.create({
    withCredentials: true,
    baseURL: 'https://bacchus-api.whitelabel-hosting.de/api'
})

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route 
  {...rest}
  render={props =>
    localStorage.getItem("isLogged") ? (
      <Component {...props} />
    ) : (
      <Redirect 
      to={{
        pathname: "/login",
        state: { from: props.location }
      }}
      />
    )
  }
  />
);

// map redux state to props
const mapStateToProps = state => {
  return {
      isLogged: state.isLogged,
      Alert: state.Alert,
  }
}
// end:: map redux state to props

// map redux dispatcher to props
const mapDispatchToProps = dispatch => {
  return {
      sign: (payload) => dispatch(sign(payload)),
      toggleAlert: (payload) => dispatch(toggleAlert(payload)),
  }
}
// end:: map redux dispatcher to props

export class App extends Component {

  // check if user is logged in
  checkLogin = async () => {

    api.get('/users/me')

    .then(function (response) {
        // handle success
        this.props.sign(true)
        localStorage.setItem("isLogged", "true")
    }.bind(this))

    .catch(function (error) {
        // handle error
        this.props.sign(false)
        localStorage.removeItem("isLogged")
    }.bind(this))

  }
  // end:: check if user is logged in

  render() {
    return (
      <div>

        <Navigation />

        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/product/:id" component={SingleProduct} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/event/:id" component={SingleEvent} />
          <Route exact path="/sortiment" component={Products} />
          <Route exact path="/products" component={ProductList} />
          <Route exact path="/datenschutz" component={Datenschutz} />
          <Route exact path="/impressum" component={Impressum} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/newsletter" component={Newsletter} />
        </Switch>

        <SweetAlert
            show={this.props.Alert.show}
            title={this.props.Alert.alert.title}
            type={this.props.Alert.alert.type}
            text={this.props.Alert.alert.message}
            onConfirm={() => this.props.toggleAlert({"show": false, "alert": {"type": "", "title": '', "message": ""}})}
        />

        <Footer />
        
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
