import ProductCategory from './ProductCategory'
import ProductSubCategory from './ProductSubCategory'
import ProductCountry from './ProductCountry'
import ProductSearch from './ProductSearch'
import isLogged from './isLogged'
import Alert from './Alert'
import { combineReducers } from 'redux'

const allReducers = combineReducers({
    Alert: Alert,
    isLogged : isLogged,
    ProductCategory : ProductCategory,
    ProductSubCategory : ProductSubCategory,
    ProductCountry : ProductCountry,
    ProductSearch : ProductSearch
});

export default allReducers;