import React, { Component } from 'react'
import Slider from "react-slick"
import axios from 'axios'
import { replaceURL } from '../migration'

const api = axios.create({
    baseURL: 'https://bacchus-api.whitelabel-hosting.de/api'
})

export class Carousel extends Component {

    constructor(props) {
        super(props)
        this.state = {
            images: []
        }
    }

    getImages = async () => {
        api.get(`/items/sliders/${this.props.slideid}`).then(res => {
            this.setState({ loading: false, images: replaceURL(res).data.data.generated_images });
        })
    }

    componentDidMount() {
        this.getImages();
    }

    render() {

        const settings = {
            draggable: false,
            dots: false,
            arrows: false,
            speed: 2000,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplay: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    }
                }
            ]
        };

        // Images
        const images = this.state.images.map((image, key) =>
            <div>
                <img className="img-fluid" alt="Ansicht Apotheke" src={image} />
            </div>
        );
        // End:: Images

        return (
            <div className="home-carousel-section">
                <Slider {...settings}>
                    {images}
                </Slider>
            </div>
        )
    }
}

export default Carousel
