import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'
import axios from 'axios'
import { replaceURL } from '../migration'

const api = axios.create({
    baseURL: 'https://bacchus-api.whitelabel-hosting.de/api'
})


export class GiftSection extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pressed: false,
            content: []
        }
    }

    getContent = async () => {
        api.get(`/items/text_blocks/3`).then(res => {
            this.setState({ loading: false, content: replaceURL(res).data.data });
        })
    }

    componentDidMount() {
        this.getContent();
    }

    render() {

        // render html directly from api
        function createMarkup(content) {
            return {__html: content};
        }
        // end:: render html directly from api

        function handleSectionColor(type) {
            if(type === "gray") {
                return "bg-light"
            }
        }

        function handleDropdown(state) {
            if(state === false) {
                return "d-none";
            }
            else {
                return "d-block";
            }
        }

        function handleDropdownbutton(state) {
            if(state === true) {
                return "d-none";
            }
            else {
                return "d-block";
            }
        }

        return (
            <div className={handleSectionColor(this.props.sectiontype)}>
                <div className="text-section">
                    <Container>
                        <Row>
                            <Col>
                                <h3 className="text-section-heading">Geschenkservice</h3>
                                {this.state.pressed === false ? (<p dangerouslySetInnerHTML={createMarkup(this.state.content.excerpt)}></p>) : ("")}
                                {this.state.pressed === true ? (<p dangerouslySetInnerHTML={createMarkup(this.state.content.content)}></p>) : ("")}
                                <div className={handleDropdownbutton(this.state.pressed)} onClick={() => {
                                    this.setState({pressed: true})
                                }}>
                                    <div className="mb-1 mt-2">
                                        <ArrowDropDownCircleIcon style={{color: '#525252'}} />
                                    </div>
                                    <div>
                                        mehr anzeigen
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
}

export default GiftSection
