import React, { Component } from 'react'
import { Container, Row, Col, Card, Button, Image } from 'react-bootstrap'
import axios from 'axios'
import DateRangeIcon from '@material-ui/icons/DateRange'
import ContactSection from '../components/layout/ContactSection'
import MetaTags from 'react-meta-tags'
import bacchusog from '../img/bacchus-og.png'
import { replaceURL } from '../migration'

const api = axios.create({
    baseURL: 'https://bacchus-api.whitelabel-hosting.de/api'
})

export class SingleEvent extends Component {

    constructor(props) {
        super(props)
        this.state = {
            event: []
        }
    }

    getEvents = async () => {
        api.get(`/items/events/${this.props.match.params.id}`).then(res => {
            this.setState({ loading: false, event: replaceURL(res).data.data });
        })
    }

    componentDidMount() {
        this.getEvents();
    }

    render() {

        // render html directly from api
        function createMarkup(content) {
            return {__html: content};
        }
        // end:: render html directly from api

        function handleDate(date){
            return date.replaceAll("-", ".")
        }

        return (
            <div>
                <MetaTags>
                    <title>Veranstalltungen - Weinhandel Bacchus</title>
                    <meta name="description" content="Aktuelle Veranstaltung des Weinhandel Bacchus. Seien Sie beim nächsten mal dabei!" />
                    <meta property="og:title" content="Veranstalltungen - Weinhandel Bacchus" />
                    <meta property="og:image" content={bacchusog} />
                </MetaTags>
               <Container>
                    <Row>
                        <Col>
                            <div className="single-section">
                                <Card>
                                    <Card.Body>
                                    <div className="d-flex flex-row">
                                        <DateRangeIcon style={{ marginRight: '10px' }} />
                                        <div className="event-card-title">{this.state.event.name}</div>
                                    </div>
                                    <div className="event-card-date">
                                        {"am " + this.state.event.date + " um " + this.state.event.time + " Uhr"}
                                    </div>
                                    <hr className="pb-1 pt-1" />
                                    <div className="single-event-desc" dangerouslySetInnerHTML={createMarkup(this.state.event.description)}></div>
                                    <div className="mt-3">
                                        <Button className="b-btn" variant="secondary">Einladung anfragen?</Button>
                                    </div>
                                    </Card.Body>
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <ContactSection color="gray" />
            </div>
        )
    }
}

export default SingleEvent
