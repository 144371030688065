import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Nav, Image } from 'react-bootstrap'
import Logo from '../../img/bacchus-logo-white.png'

export class Navigation extends Component {
    render() {
        return (
            <div style={{ backgroundColor: '#505050' }}>
                <div className="container navigation-container">
                    <Navbar variant="dark" expand="lg">
                        <Link to="/">
                            <div className="navbar-brand">
                                <Image width="125" src={Logo} />
                            </div>
                        </Link>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                <Link to="/"><div className="nav-link">Home</div></Link>
                                <Link to="/sortiment"><div className="nav-link">Sortiment</div></Link>
                                <Link to="/products"><div className="nav-link">Artikel</div></Link>
                                <Link to="/events"><div className="nav-link">Veranstaltungen</div></Link>
                                <Link to="/contact"><div className="nav-link">Kontakt</div></Link>
                                <Link to="/newsletter"><div className="nav-link">Newsletter</div></Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
        )
    }
}

export default Navigation
