import React, { Component } from 'react'
import ContactSection from '../components/layout/ContactSection'
import ContactForm from '../components/ContactForm'
import MetaTags from 'react-meta-tags'
import bacchusog from '../img/bacchus-og.png'

export class Contact extends Component {
    render() {
        return (
            <div>
                <MetaTags>
                    <title>Kontakt aufnehmen - Weinhandel Bacchus</title>
                    <meta name="description" content="Nehmen Sie kontakt mit uns auf, wir werden uns schnellstmöglich mit Ihnen in Verbindung setzen." />
                    <meta property="og:title" content="Kontakt aufnehmen - Weinhandel Bacchus" />
                    <meta property="og:image" content={bacchusog} />
                </MetaTags>
                <ContactForm />
                <ContactSection color="gray" />
            </div>
        )
    }
}

export default Contact
