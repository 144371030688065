const ProductSubCategory = (state = "", action) => {
    switch(action.type){
        case "SETPRODUCTSUBCATEGORY":
            state = action.payload;
        default:
            return state;
    }
};

export default ProductSubCategory;
