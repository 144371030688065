import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export class Footer extends Component {
    render() {
        return (
            <div className="footer-wrapper">
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <div className="d-flex flex-row">
                                    <div className="d-block d-md-none">
                                        © 2020
                                    </div>
                                    <div className="d-none d-md-block">
                                        © Copyright Flippink E-Commerce Solutions UG
                                    </div>
                                    <div className="d-flex flex-row ml-auto">
                                        <Link to="/datenschutz">
                                            <div className="mr-3 link-unstyled-white">
                                                Datenschutz
                                            </div>
                                        </Link>
                                        <Link to="/impressum">
                                            <div className=" link-unstyled-white">
                                                Impressum
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Footer
