const ProductSearch = (state = "", action) => {
    switch(action.type){
        case "SETPRODUCTSEARCH":
            state = action.payload;
        default:
            return state;
    }
};

export default ProductSearch;
